/* Let's default this puppy out
-------------------------------------------------------------------------------*/

html, body, body div, span, object, iframe, h1, 
h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address,
 cite, code, del, dfn, em, img, ins, kbd, q, samp, small,
  strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, 
  fieldset, form, label, legend, table, caption, tbody, 
  tfoot, thead, tr, th, td, article, aside, figure, footer, 
  header, menu, nav, section, time, mark, audio, video, details, summary {
	margin: 0;
	padding: 0;
	border: 0;
    font-family: 'chainhawk';
	font-size: 100%;
	font-weight: normal;
	vertical-align: baseline;
	background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {display: block;}

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */

*, body,
*:before,
*:after {
	box-sizing: border-box;
}


/* Responsive images and other embedded objects
*/
img, object, embed {max-width: 100%;}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/

/* force a vertical scrollbar to prevent a jumpy page */
html {overflow-y: scroll;overflow-x: hidden}

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before,
blockquote:after,
q:before,
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

/* tables still need cellspacing="0" in the markup */
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle;}

pre {
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
}


select, input, textarea {font: 99% sans-serif;}

table {font-size: inherit; font: 100%;}

small {font-size: 85%;}

strong {font-weight: bold;}

td, td img {vertical-align: top;}

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

/* standardize any monospaced elements */
pre, code, kbd, samp {font-family: monospace, sans-serif;}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {cursor: pointer;}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0; padding: 0;}

/* scale images in IE7 more attractively */
.ie7 img {-ms-interpolation-mode: bicubic;}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/

/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }

/* a tag text-decoration hiding */
a{
	text-decoration: none;
}

/* li tag style reseting */
li{
	list-style-type: none;
}

/* all input appearance reseting*/
input, button{
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	outline: 0;
	border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
input[type="radio"] {
	vertical-align: text-bottom;
    -webkit-appearance: radio;
}
input[type="checkbox"] {
	vertical-align: bottom;
    -webkit-appearance: checkbox;
}

.ie7 input[type="checkbox"] {vertical-align: baseline;}
.ie6 input {vertical-align: text-bottom;}

/* number type inupt spinners hiding */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
-webkit-appearance: none;
    margin: 0;
}

body {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background-image: url('../assets/banner.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

section {
	padding: 20px 0;
}

section, #root {
	width: 100%;
	height: 100%;
}

@media (max-width: 768px) {
	.mint {
		display: flex;
		flex-direction: column-reverse;
		gap: 50px;
	}
}

.mint--container{
	position: relative;
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	z-index: 120;
}

.mint__account {
	text-align: center;
	color: #fff;
	margin-top: 12px;
	margin-bottom: 20px;
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;
}

.avatar{
	position: absolute;
	left: 5%;
	bottom: 12%;
	width: 180px;
	height: 300px;
	object-fit: contain;
}

.avatar-right{
	left: auto;
	right: 5%;
}

.form-counter{
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
	margin-block-end: 30px;
    font-size: 40px;
    gap: 15px;
	background-color: #fff;
    color: #000;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: inset 0 0 0 2px #333;
}

.form-counter span {
	width: 70px;
	text-align: center;
}

.counter-btn{
	margin: 0 -5px;
	flex-grow: 1;
	height: 46px;
    align-self: center;
    font-size: 40px;
	background-color: #333;
    color: #fff
  }
  
  .counter-btn[data-pos="minus"] {
    position: relative;
  }

  .btn--connect{
    margin-block-start: 30px;
  }
  
  .btn--mint {
	position: relative;
	display: flex;
	justify-content: center;
	margin: auto;
	padding: 20px 48px;
	background: linear-gradient(to bottom, #9f32b5, #770399);
	border: solid #61a7e3 4px;
	color: #fff;
	text-align: center;
	border-radius: 18px;
	font-family: 'chainhawk';
	font-size: 32px;
	text-transform: uppercase;
	letter-spacing: 3px;
  }

  .btn--mint:after {
	content: "";
	position: absolute;
	background-color: #61a7e3;
	border-radius: 10px;
	height: 102%;
	width: 0;
	transition: width .4s;
	left: 0;
	top: -1px;
	z-index: 1;
  }
  .btn--mint span {
	position: relative;
	z-index: 2;
  }

  .btn--mint:hover:after {
	width: 100%;
  }

  .btn--claim {
	background-color: #48d8ed;
  }

  iframe {
	display: none;
  }

  .mint__wrap {
	position: relative;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 100%;
	height: 104px;
	border: 5px solid #000;
	outline: 2px solid #999;
	margin-block-end: 10px;
	font-size: 30px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
	background: linear-gradient(to bottom, #9f32b5, #770399);
  }

  .btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	width: 100%;
	height: 150px;
	border: 5px solid #000;
	outline: 2px solid #999;
	font-size: 30px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
  }

  .mint__images {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
  }

  .mint__images img {
	transition: 1s transform;
  }

  .mint__images img:nth-child(1) {
	animation:rotateToLeft  1s;
  }

  .mint__images img:nth-child(2) {
	animation:toBottom 1.5s;
  }

  .mint__images img:nth-child(3) {
	animation: rotateToRight 1s;
  }

  @keyframes rotateToLeft {
	0% {
		transform: rotate(-360deg);
	}
	100% {
		transform: rotate(0);
	}
  }

  @keyframes rotateToRight {
	0% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0);
	}
  }
  
  @keyframes toBottom {
	0% {
		transform: translate(0, -280px);
	}
	100% {
		transform: translate(0);
	}
  }

  .mint__images img:hover {
	transform: scale(1.05);
  }

  
  @media screen and (max-width: 768px) {
	.mint__images {
		align-items: center;
	}
  }

  .mint__images img {
	width: 210px;
	max-width: 100%;
  }

  .mint__nft-left {
	text-align: center;
	font-weight: 700;
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
	margin: auto auto 16px;
  }

  .mint__koda {
	display: block;
	width: 150px;
	height: 150px;
	object-fit: cover;
	margin: 0 auto;
	animation: toTop 1s;
  }

  @keyframes toTop {
	0% {
		transform: translate(0, 50px);
	}
	100% {
		transform: translate(0);
	}
  }

  @media screen and (max-width: 1366px) {
	body {
		align-items: flex-start;
	}
	#home {
		padding-block-start: 100px;
	}
  }


  @media screen and (max-width: 992px) {
	.mint--container{
		padding: 0 22px;
	}
  }

  @media screen and (max-width: 768px) {
	body {
		align-items: center
	}
	.avatar {
		display: none;
	}
	#home {
		padding-block-start: 22px;
	}
  }

  .mb-0 {
	margin-bottom: 0;
  }